<template>
    <div>
        <div class="date">
            <span v-if="activated">
                <span v-if="loggedIn">{{ date }}</span>
                <span v-if="!loggedIn">Never logged in</span>
            </span>
            <span v-else
                  class="d-flex align-center"
            >
                <span>
                    Not activated yet
                </span>
                <resend-activation-mail 
                    :user="user" 
                    class="ml-2"
                />
            </span>
        </div>
    </div>
</template>
<script>
    import ResendActivationMail from '@/components/pages/users/ResendActivationMail';
    import moment from 'moment-timezone';
    export default {
        name: 'UserLastLogin',
        components: {ResendActivationMail},
        props: {
            user: {
                type: Object,
                default: () => {
                    return {
                        last_login_at: null,
                        activated_at: null,
                    };
                },
            }
        },
        computed:{
            date() {
                return  moment.utc(this.user.last_login_at).tz('Europe/Amsterdam').format('YYYY-MM-DD HH:mm');
            },
            loggedIn() {
                return !!this.user.last_login_at;
            },
            activated() {
                return !!this.user.activated_at;
            },
            //TODO: resend activation mail with confirmation
        }
    };
</script>

<style scoped>

</style>
