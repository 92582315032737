<template>
    <div class="home">
        <user-list-view />
    </div>
</template>

<script>
    import UserListView from './users/UserListView';
    export default {
        name: 'Home',
        components: {UserListView},
    };
</script>
