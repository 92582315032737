import store from '../store/index';
import auth from './auth';

/**
 * Use this for errors
 * @param message
 */
export const toastError = (message) => toastMessage(message, 'error', 3000);


/**
 * Use this for success
 * @param message
 */
export const toastSuccess = (message) => toastMessage(message, 'success', 2000);

/**
 * Use this for info
 * @param message
 */
export const toastInfo = (message) => toastMessage(message, 'info', 2000);

/**
 * Use this for generic messages
 * @param message
 */
export const toast = (message) => toastMessage(message, 'grey', 2000);

/**
 * Use this for customized messages (custom type and custom timeout (default: no timeout))
 * @param message
 * @param type // default: 'grey' - generic
 * @param timeout // default: no timeout
 */
export const toastMessage = (message, type = 'med-grey-1', timeout = -1) => {
    store.commit('snackbar/hideMessage');
    store.commit('snackbar/showMessage', {
        content: message,
        type: type,
        timeout: timeout
    });
};

export const handleServerErrors = () => {
    toastError('An unexpected error occurred. Please try again later.');
};

export const handleBadRequest = (error) => {
    if (error.response.data.message) {
        toastError(error.response.data.message);
        return;
    }
    toastError('We couldn\'t process your request. Please try again later.');
};

export const handleNotFound = (e) => {
    if (e.response.data.error) {
        toastError(e.response.data.error.message);
    } else if (e.response.message) {
        toastError(e.response.data.message);
    } else {
        toastError(e.response.statusText);
    }
};

export const handleUnprocessable = (error) => {
    const errors = error.response.data;
    for (let key in errors) {
        let errorMessage = Array.isArray(errors[key]) ? errors[key].join('<br />') : errors[key].message || errors[key];
        if (typeof errorMessage !== 'string') {
            errorMessage = '';
            try {
                for (let prop in errors[key]) {
                    if (Object.prototype.hasOwnProperty.call(errors[key], prop)) {
                        for (let i = 0; i < errors[key][prop].length; ++i) {
                            errorMessage = errorMessage + `${prop}: ${errors[key][prop][i]} <br/>`;
                        }
                    }
                }
            } catch (e) {
                errorMessage = JSON.stringify(errors[key]);
            }
        }
        toastError(errorMessage, 'warning');
    }
};

export const handleAuthenticationRequired = (e) => {
    if (e.response.data.error) {
        toastError(e.response.data.error);
        return;
    }
    toastError('You are not allowed to access this feature.');
};

export const handleTooManyAttempts = () => toastError('You have reached your request limit. Please try again in a minute.');

export const handleTooManyLoginAttempts = () => toastError('You have reached your login attempt limit. Please try again in a minute.');

export const handleForbidden = e => {
    toastError(e.response.data.message ?? 'Forbidden');
    auth.logout();
};


