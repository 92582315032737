import Vue from 'vue';
import Vuex from 'vuex';
import SnackbarStore from './snackbar';
import AuthStore from './auth';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        snackbar: SnackbarStore,
        auth: AuthStore
    }
});
