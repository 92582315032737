<template>
    <div>
        <v-btn v-if="showRevoke"
               small
               class="error"
               :loading="loading"
               @click="askRevoke"
        >
            Revoke
        </v-btn>
        <v-btn v-if="showReactivate"
               small
               class="primary"
               :loading="loading"
               @click="askReactivate"
        >
            <span v-if="product.grantOnly">Grant</span>
            <span v-else>Reactivate</span>
        </v-btn>
        <v-btn v-if="showCreate"
               small
               outlined
               target="_blank"
               :href="product.createUrl"
        >
            Create
            <v-icon small
                    class="ml-1"
            >
                mdi-open-in-new
            </v-icon>
        </v-btn>

        <component :is="confirmationDialog"
                   ref="confirmationDialog"
                   :user="user"
                   :product="product"
        />
    </div>
</template>
<script>
    import { reactivateApiUrl, revokeApiUrl } from '../../../services/api';
    import RevokeAccessModal from './modals/RevokeAccessModal';
    import GrantAccessModal from './modals/GrantAccessModal';
    import ReactivateAccessModal from './modals/ReactivateAccessModal';

    export default {
        name: 'UserAccessState',
        props: {
            value: {
                type: Number,
                coerce: v => {
                    return parseInt(v);
                },
                default: () => 0
            },
            user: {
                type: Object,
                default: () => {return {name: ''};}
            },
            product: {
                type: Object,
                default: () => {return {name: '', id: '', createUrl: ''};}
            }
        },
        components: {
            RevokeAccessModal,
            GrantAccessModal,
            ReactivateAccessModal
        },
        data() {
            return {
                modal: false,
                revoking: 'true',
                loading: false,
            };
        },
        computed: {
            showReactivate() {
                if (this.value === 0 && this.product.grantOnly) {
                    return true;
                }

                return this.value === 2;
            },
            showRevoke() {
                return this.value === 1;
            },
            showCreate() {
                if (this.product.grantOnly) {
                    return false;
                }

                return this.value === 0;
            },
            confirmationDialog() {
                if (this.revoking) {
                    return 'revoke-access-modal';
                }

                return this.product.grantOnly ? 'grant-access-modal' : 'reactivate-access-modal';
            }
        },
        methods: {
            async askRevoke() {
                this.loading = false;
                this.revoking = true;
                await this.$nextTick();
                const result = await this.$refs.confirmationDialog.show();
                if (result) {
                    await this.revoke();
                }
            },
            async askReactivate() {
                this.loading = false;
                this.revoking = false;
                await this.$nextTick();
                const result = await this.$refs.confirmationDialog.show();

                if (result) {
                    await this.reactivate();
                }
            },
            async revoke() {
                this.loading = true;
                await this.$axios.put(revokeApiUrl(this.user.id), {product: this.product.id});
                this.$emit('input', 2);
                this.loading = false;
            },
            async reactivate() {
                this.loading = true;
                await this.$axios.put(reactivateApiUrl(this.user.id), {product: this.product.id});
                this.$emit('input', 1);
                this.loading = false;
            },
        },
    };
</script>

<style scoped>

</style>
