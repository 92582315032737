<template>
    <div class="list-column sortable"
         @click="toggleSort"
    >
        <h4>
            {{ name }}
        </h4>
        <div class="header-filters"
             :class="{'active' : isSorted(asc, desc)}"             
        >
            <v-btn
                icon
                small
                class="sort-button"
                :color="isSortedBy(asc) ? 'black' : 'grey lighten-2'"
                @click.stop="sort(asc)"
            >
                <v-icon dark
                        small
                >
                    mdi-arrow-up
                </v-icon>
            </v-btn>
            <v-btn
                icon
                small
                class="sort-button"
                :color="isSortedBy(desc) ? 'black' : 'grey lighten-2'"
                @click.stop="sort(desc)"
            >
                <v-icon dark
                        small
                >
                    mdi-arrow-down
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'UserListHeaderItem',
        props: {
            currentSorting: {
                type: String,
                required: true
            },
            asc: {
                type: String,
                required: true
            },
            desc: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },
        methods: {
            toggleSort() {
                if(this.currentSorting === this.asc) {
                    this.$emit('sort', this.desc);
                }else {
                    this.$emit('sort', this.asc);
                }                
            },
            sort(value) {
                if(!this.isSortedBy(value)){
                    this.$emit('sort', value);
                }
            },
            isSorted(asc, desc) {
                return this.currentSorting === asc || this.currentSorting === desc;
            },
            isSortedBy(value) {
                return this.currentSorting === value;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .header-filters {
        min-width: 72px;
        &.active {
            display: block;
        }
        .sort-button {
            display: inline-block;
        }
    }
</style>