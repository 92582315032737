<template>
    <v-main class="quadia-live-admin grey lighten-4">
        <app-bar />
        <router-view />
        <snack-bar />
    </v-main>
</template>

<script>
    import AppBar from '@/components/controls/app-bar/AppBar';
    import SnackBar from '@/components/controls/SnackBar';

    export default {
        name: 'MainLayout',
        components: {AppBar, SnackBar},
    };
</script>