<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-progress-linear indeterminate
                                       color="primary"
                    />
                    <v-card-text>
                        Please wait...
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import crypto from 'crypto-js';
    import { getInternalUrl, makeSSOsUrl } from '@/services/api';

    export default {
        name: 'Login',
        computed: {
            loginUrl() {
                return makeSSOsUrl('/sso/auth') +
                    '?client_id=' + process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID + '&' +
                    'redirect_uri=' + getInternalUrl({'name':'AuthCallback'}) + '&' +
                    'response_type=code&scope=*&' +
                    'state=' + this.state + '&' +
                    'code_challenge=' + this.challenge + '&' +
                    'code_challenge_method=S256';
            }
        },
        mounted() {
            this.state = this.createRandomString(40);
            const verifier = this.createRandomString(128);

            this.challenge = this.base64Url(crypto.SHA256(verifier));
            window.localStorage.setItem('state', this.state);
            window.localStorage.setItem('verifier', verifier);

            window.location.href = this.loginUrl;
        },
        methods: {
            createRandomString(num) {
                return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
            },

            base64Url(string) {
                return string.toString(crypto.enc.Base64)
                    .replace(/\+/g, '-')
                    .replace(/\//g, '_')
                    .replace(/=/g, '');
            }
        }
    };
</script>

<style scoped>

</style>
