<template>
    <div>
        <h1 class="page-header font-weight-regular mx-8 my-4">
            Quadia users
            <div class="search-container ml-16">
                <v-text-field v-model="search"
                              solo-inverted
                              clearable
                              dense
                              flat
                              hide-details
                              placeholder="Search..."
                />
            </div>
        </h1>
        <div class="list-outer mx-8">
            <div class="list-inner"
                 :class="loading ? 'loading' : ''"
            >
                <div class="list-view">
                    <user-list-header :current-sorting="currentSorting"
                                      :loading="loading"
                                      @sort="sort"
                    />
                </div>
                <div v-if="!loading"
                     class="list-view"
                >
                    <user-list-item v-for="user in users"
                                    :key="`user-${user.id}`"
                                    :user="user"
                                    :loading="loading"
                    />
                </div>
                <div v-else>
                    <v-progress-linear
                        indeterminate
                    />
                </div>
            </div>
            <div class="pagination-container my-4">
                <label class="mr-2 pb-0">Page size:</label>
                <div class="page-size-container">
                    <v-select v-model="pagination.perPage"
                              :items="pagination.perPageOptions"
                              hide-details
                              class="mt-0 pt-0"
                              outlined
                              dense
                              solo
                    />
                </div>
                <v-pagination v-if="pagination.total > 1"
                              v-model="currentPage"
                              :length="pagination.total"
                              total-visible="10"
                              color="secondary"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import UserListHeader from '@/components/pages/users/UserListHeader';
    import UserListItem from './UserListItem';
    import {debounce} from 'lodash';
    import { listingUrl } from '../../../services/api';
    export default {
        name: 'UserListView',
        components: {
            UserListItem,
            UserListHeader,
        },
        data() {
            return {
                users: [],
                loading: true,
                search: '',
                pagination: {
                    total: 2,
                    perPage: 10,
                    perPageOptions: [10,25,50,100]
                },
                currentPage: 1,
                currentSorting: 'name_asc'
            };
        },
        watch: {
            search() {
                this.currentPage = 1;
                this.getUsers();
            },
            currentPage() { this.getUsers(); },
            'pagination.perPage'() { this.getUsers(); },
        },
        methods: {
            sort(value) {
                this.getUsers(value);
                this.currentSorting = value;
            },
            getUsers: debounce(async function(value) {
                this.loading = true;
                const params = new URLSearchParams({
                    sort: value ? value : this.currentSorting,
                    page: this.currentPage,
                    per_page: this.pagination.perPage
                });

                if (this.search) {
                    params.append('search', this.search);
                }

                const response = await this.$axios.get(`${listingUrl}?${params.toString()}`).then(r => r.data);
                this.currentSorting = response.current_sorting;
                this.pagination.total = response.last_page;
                this.users = response.data;
                this.loading = false;
            }, 400)
        },
        mounted() {
            this.getUsers();
        }
    };
</script>

<style scoped lang="scss">
    .search-container {
        display: inline-block;
        width: 250px;
    }

    .pagination-container {
        display: flex;
        justify-content: center;
        & > * {
            flex: 0 0 auto
        }

        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .page-size-container {
            width: 6rem;
        }
    }
</style>
