<template>
    <div>
        <v-tooltip
            v-if="!loading"
            bottom
        >
            <template v-slot:activator="{on}">
                <v-btn
                    icon
                    v-on="on"
                    @click="resendActivationMail(user)"
                >
                    <v-icon dark>
                        mdi-email-send
                    </v-icon>
                </v-btn>
            </template>
            <span>Resend activation mail</span>
        </v-tooltip>
        <v-progress-circular v-else
                             indeterminate
                             color="grey"
        />
        <v-snackbar
            v-model="success"
            timeout="2500"
            color="success"
            class="text-center"
        >
            Activation mail re-sent!
            <v-icon dark
                    large
            >
                check_circle
            </v-icon>
        </v-snackbar>
    </div>
</template>


<script>
    import {resendActivationMailUrl} from '@/services/api';

    export default {
        name: 'ResendActivationMail',
        props:{
            user: {
                type: Object,
                required: true
            }
        },
        data(){
            return {
                loading: false,
                success: false
            };
        },
        methods:{
            resendActivationMail(){
                this.loading = true;
                this.$axios.post(resendActivationMailUrl(this.user.id))
                    .then(() => {
                        this.success = true;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
</script>