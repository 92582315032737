<template>
    <div class="container">
        <v-row>
            <v-col class="vd-flex pa-0"
                   cols="12"
            >
                <div class="text-center logo-404 pt-12">
                    <div class="logo-img">
                        <img src="/images/logo-quadia-horizontal-united-w300.svg"
                             class="mb-10 mt-10 centered"
                        >
                    </div>
                </div>
                <p class="big-four-oh-four text-center primary--text">
                    404
                </p>
                <h1 class="text-center">
                    We're sorry!
                </h1>
                <p class="text-center smaller-text primary--text text--lighten-2 pb-12">
                    We don't have that.
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    };
</script>

<style scoped>
.container {
    margin: 0 auto;
    padding: 0;
    height: 100vh;
}

.container .row {
    height: 100%;
}

.vd-flex > * {
    flex: 0 0 auto;
}

.vd-flex > .big-four-oh-four {
    font-size: 10rem;
}

.logo-img{
        max-width: 300px;
        margin: 0 auto;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.smaller-text {
    font-size: 1.3rem;
}

.vd-flex {
    height: 100%;
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 2.25rem;
    font-weight: 400;
}
</style>