import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/pages/Home.vue';
import MainLayout from '../components/layouts/MainLayout';
import AuthLayout from '../components/layouts/AuthLayout';
import NotFound from '../components/layouts/NotFound';
import Login from '../components/pages/Login';
import AuthCallback from '../components/pages/AuthCallback';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {name: 'Home'},
        component: MainLayout,
        children: [
            {
                path: 'users',
                component: Home,
                name: 'Home',
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            { path: '/login', name: 'Login', component: Login},
            { path: '/auth', name: 'AuthCallback', component: AuthCallback}
        ]

    },
    {
        path: '*',
        component: NotFound,
        name: 'NotFound'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.auth.authenticated) {
        next({
            name: 'Login',
            params: { nextUrl: to.fullPath }
        });
    }

    next();
});

export default router;
