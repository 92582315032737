<template>
    <div class="list-item">
        <div class="list-column name"
             :class="!loading ? 'fix' : ''"
        >
            {{ user.name }}
        </div>
        <div class="list-column email"
             :class="!loading ? 'fix' : ''"
        >
            {{ user.email }}
        </div>
        <div class="list-column date">
            <user-last-login :user="user" />
        </div>
        <div v-for="product in products"
             :key="`list-item-${product.id}-${user.id}`"
             class="list-column action"
        >
            <user-access-state v-model="user[product.userKey]"
                               :product="product"
                               :user="user"
            />
        </div>
    </div>
</template>
<script>
    import UserAccessState from './UserAccessState';
    import UserLastLogin from './UserLastLogin';
    import { products } from '../../../services/api';
    export default {
        name: 'UserListItem',
        components: {UserLastLogin, UserAccessState},
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            user: {
                type: Object,
                required: true
            }
        },
        computed: {
            products() {
                return products;
            }
        }
    };
</script>
