<template>
    <v-dialog v-model="modal"
              persistent
              max-width="600"
    >
        <v-card>
            <v-card-title>
                Granting access for {{ user.name }} to {{ product.name }}
            </v-card-title>
            <v-card-text>
                <p>This will allow <strong>{{ user.name }}</strong> to access <strong>{{ product.name }}</strong>.</p>
                <p> Would you like to continue?</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined
                       color="#666666"
                       @click="cancel"
                >
                    Cancel
                </v-btn>
                <v-btn class="primary"
                       outlined
                       dark
                       @click="confirm"
                >
                    Yes,&nbsp;grant access
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'GrantAccessModal',
        props: {
            user: {
                type: Object,
                required: true,
            },
            product: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                modal: false,
                resolve: null,
            };
        },
        methods: {
            closeModal() {
                this.modal = false;
            },
            async show() {
                return new Promise((resolve) => {
                    this.resolve = resolve;
                    this.modal = true;
                });
            },
            cancel() {
                this.closeModal();
                this.resolve(false);
            },
            confirm() {
                this.closeModal();
                this.resolve(true);
            }
        },
        mounted() {
            this.resolve = null;
        }
    };
</script>

<style scoped>

</style>
