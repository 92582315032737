'use strict';

import Vue from 'vue';
import axios from 'axios';
import {
    handleAuthenticationRequired,
    handleBadRequest,
    handleForbidden, handleNotFound,
    handleServerErrors, handleTooManyAttempts, handleTooManyLoginAttempts, handleUnprocessable,
} from '../services/errorHandling';
import auth from '../services/auth';
import store from '../store/index';
import {
    getRequestIdentifier,
    makeSSOsUrl
} from '../services/api';
import router from '../router';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    auth.checkAuth();
    if (store.state.auth.authenticated) {
        // don't add authorization header if it's already specified
        if (!config.headers.Authorization) {
            config.headers.Authorization = auth.getAuthHeader();
        }
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const numberOfRetries = {};

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    auth.checkAuth();
    const identifier = getRequestIdentifier(response);
    if (numberOfRetries[identifier]) {
      delete numberOfRetries[identifier];
    }
    return response;
  },
  async function(e) {
    // Do something with response error
      if (typeof e.response === 'undefined') {
          return Promise.reject(e);
      }

      switch (e.response.status) {
          default:
          case 500:
              handleServerErrors();
              break;
          case 400:
              handleBadRequest(e);
              break;
          case 401: // handled elsewhere
              break;
          case 403: {
              handleForbidden();
              break;
          }
          case 404:
              handleNotFound(e);
              break;
          case 422:
              handleUnprocessable(e);
              break;
          case 429:
              if (router.history.current.name === 'login') {
                  handleTooManyLoginAttempts(e);
              } else {
                  handleTooManyAttempts(e);
              }
              break;
      }

      if (e.response.status === 401) {
          const identifier = getRequestIdentifier(e.response);
          const requestData = e.response.config.data && typeof e.response.config.data === 'string' ? JSON.parse(e.response.config.data) : null;
          if (requestData && requestData.grant_type && requestData.grant_type === 'refresh_token') {
              auth.logout();
              window.location.href = makeSSOsUrl('/sso/logout');
          } else {
              await auth.refresh();

              if (Object.prototype.hasOwnProperty.call(numberOfRetries, identifier)) {
                  numberOfRetries[identifier] += 1;
              } else {
                  numberOfRetries[identifier] = 0;
              }

              if (numberOfRetries[identifier] <= 4) {
                  e.response.config.headers['Authorization'] = auth.getAuthHeader();
                  return window.axios.request(e.response.config);
              } else {
                  handleAuthenticationRequired(e);
              }
          }
      }
      return Promise.reject(e);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin);

export default Plugin;
