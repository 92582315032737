import router from '../router';

export const products = [
    {
        id: 'platform',
        name: 'Video Platform',
        managementUrl: process.env.VUE_APP_PLATFORM_USERS_URL,
        createUrl: `${process.env.VUE_APP_PLATFORM_USERS_URL}/create`,
        userKey: 'access_platform',
    },
    {
        id: 'quadiaLive',
        name: 'Quadia Live',
        managementUrl: process.env.VUE_APP_QLIVE_USERS_URL,
        createUrl: `${process.env.VUE_APP_QLIVE_USERS_URL}/create`,
        userKey: 'access_quadia_live',
    },
    {
        id: 'liveDirector',
        name: 'LiveDirector',
        managementUrl: process.env.VUE_APP_LDIR_USERS_URL,
        createUrl: `${process.env.VUE_APP_LDIR_USERS_URL}/create`,
        userKey: 'access_live_director',
    },
    {
        id: 'studioMessenger',
        name: 'Studio Messenger',
        managementUrl: process.env.VUE_APP_STUDIO_MESSENGER_USERS_URL,
        createUrl: process.env.VUE_APP_STUDIO_MESSENGER_USERS_URL,
        userKey: 'access_studio_messenger'
    },
    {
        id: 'admin',
        name: 'Accounts Admin',
        grantOnly: true,
        userKey: 'access_admin',
    },
];

export const listingUrl = `${process.env.VUE_APP_ACCOUNTS_API_HOST}/accounts-admin/users`;
export const revokeApiUrl = (userId) => `${process.env.VUE_APP_ACCOUNTS_API_HOST}/accounts-admin/users/${userId}/revoke`;
export const reactivateApiUrl = (userId) => `${process.env.VUE_APP_ACCOUNTS_API_HOST}/accounts-admin/users/${userId}/reactivate`;
export const resendActivationMailUrl = (userId) => `${process.env.VUE_APP_ACCOUNTS_API_HOST}/accounts-admin/users/${userId}/resend-activation-mail`;
export const makeSSOsUrl = path => `${process.env.VUE_APP_SSO_HOST}${path}`;
export const getInternalUrl = params => `${location.protocol}//${location.host}${router.resolve(params).href}`;
export const getRequestIdentifier = request => `${request.config.method}:${request.config.url}`;
