const AuthStore = {
    namespaced: true,
    state: {
        authenticated: false,
        user: {
            id: null,
            email: '',
            name: '',
        }
    },
    getters:  {
        user: state => state.user,
    },
    mutations: {
        login(state, payload) {
            state.authenticated = true;
            state.user.email = payload?.email || '';
            state.user.name = payload?.name || '';
            state.user.id = payload?.id || '';
        },
        logout(state) {
            state.authenticated = false;
            state.user = {
                id: null,
                email: '',
                name: '',
            };
        }
    }
};

export default AuthStore;
