import store from '../store/index';
import jwtDecode from 'jwt-decode';
import { getInternalUrl, makeSSOsUrl } from './api';

const auth = {
    refreshIsInProgress: false,
    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        store.commit('auth/logout');
    },

    async askForToken(code) {
        const url = makeSSOsUrl('/sso/token');
        const loginResponse = await window.axios.post(url, {
            grant_type: 'authorization_code',
            client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
            redirect_uri: getInternalUrl({name: 'AuthCallback'}),
            code_verifier: window.localStorage.getItem('verifier'),
            code
        });

        window.localStorage.removeItem('state');
        window.localStorage.removeItem('verifier');

        localStorage.setItem('access_token', loginResponse.data.access_token);
        localStorage.setItem('refresh_token', loginResponse.data.refresh_token);

        this.checkAuth();
    },

    async refresh() {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            window.location.href='/login';
            return Promise.reject();
        }

        if (this.refreshIsInProgress) {
            while (this.refreshIsInProgress) {
                await (new Promise(resolve => setTimeout(resolve, 200)));
            }
            return Promise.resolve();
        }

        this.refreshIsInProgress = true;

        const url = makeSSOsUrl('/sso/token');
        const response = await window.axios.post(url, {
            grant_type: 'refresh_token',
            client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
            refresh_token: refreshToken
        }).finally(() => this.refreshIsInProgress = false);

        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('refresh_token', response.data.refresh_token);

        this.checkAuth();
        return Promise.resolve();
    },

    getAuthHeader() {
        return `Bearer ${localStorage.getItem('access_token')}`;
    },

    checkAuth() {
        const jwt = localStorage.getItem('access_token');
        if (!jwt) {
            store.commit('auth/logout');
            return;
        }
        store.commit('auth/login', jwtDecode(jwt));
    }
};

auth.checkAuth();

export default auth;
