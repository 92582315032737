<template>
    <div class="list-header">
        <user-list-header-item name="Name"
                               asc="name_asc"
                               desc="name_desc"
                               :current-sorting="currentSorting"
                               class="name"
                               :class="!loading ? 'fix' : ''"
                               @sort="sort"
        />
        <user-list-header-item name="Email"
                               asc="email_asc"
                               desc="email_desc"
                               :current-sorting="currentSorting"
                               class="email"
                               :class="!loading ? 'fix' : ''"
                               @sort="sort"
        />
        <user-list-header-item :name="lastLoginHeader"
                               asc="last_login_asc"
                               desc="last_login_desc"
                               :current-sorting="currentSorting"
                               class="date"
                               @sort="sort"
        />
        <div v-for="product in products"
             :key="`product-header-${product.id}`"
             class="list-column action"
        >
            <a v-if="product.managementUrl"
               :href="product.managementUrl"
               target="_blank"
               :title="'Manage users of '+ product.name"
            >{{ product.name }}</a>
            <span v-else>{{ product.name }}</span>
        </div>
    </div>
</template>
<script>
    import { products } from '../../../services/api';
    import UserListHeaderItem from './UserListHeaderItem.vue';
    import moment from 'moment-timezone';

    export default {
        name: 'UserListHeader',
        components: {UserListHeaderItem},
        props: {
            currentSorting: {
                type: String,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            liveDirectorUserManagement() {
                return process.env.VUE_APP_LDIR_USERS_URL;
            },
            quadiaLiveUserManagement() {
                return process.env.VUE_APP_QLIVE_USERS_URL;
            },
            platformUserManagement() {
                return process.env.VUE_APP_PLATFORM_USERS_URL;
            },
            products() {
                return products;
            },
            lastLoginHeader() {
                const zone = moment.tz('Europe/Amsterdam').format('z');
                return `Last Login (${zone})`;
            }
        },
        methods: {
            sort(value) {
                this.$emit('sort', value);
            },
        }
    };
</script>

<style lang="scss" scoped>
.list-column{
     h4{
        font-weight: inherit;
    }
}
.list-header {
    white-space: nowrap;
}
</style>
